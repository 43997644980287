import logo from './logo.svg';
import Layout from "./components/Layouts/Layout";
import AddCoin from './pages/AddCoin'
import Login from './pages/Login'
import CoinPage from './pages/CoinPage'
import Promote from './pages/Promote'
import Dashboard from './pages/Dashboard'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "./firebaseConfig"
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";


const PromoteLayout = () => {
  return(

    <FirebaseAuthConsumer>
    {({ isSignedIn, user, providerId }) => {
      return (
        <Layout
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}>
      <Promote/>
    </Layout>
      );
    }}
    </FirebaseAuthConsumer>
    
  
  )
}


const LoginLayout = () => {
  return(
    <FirebaseAuthConsumer>
    {({ isSignedIn, user, providerId }) => {
      return (
      <Layout
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}>
          <Login
            isSignedIn={isSignedIn}
            user={user}
            providerId={providerId}
          />
      </Layout>
      );
    }}
    </FirebaseAuthConsumer>
  )
}


const DashboardLayout = () => {
  return(

    <FirebaseAuthConsumer>
    {({ isSignedIn, user, providerId }) => {
      return (
        <Layout
        isSignedIn={isSignedIn}
        user={user}
        providerId={providerId}>
          <Dashboard user={user}/>
        </Layout>
      );
    }}
    </FirebaseAuthConsumer>
  )
}

const AddCoinLayout = () => {
  return(
  <FirebaseAuthConsumer>
  {({ isSignedIn, user, providerId }) => {
    return (
    <Layout
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}>
        <AddCoin
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}
        />
    </Layout>
    );
  }}
  </FirebaseAuthConsumer>
  )
}


const CoinPageLayout = () => {
  return(
  <FirebaseAuthConsumer>
  {({ isSignedIn, user, providerId }) => {
    return (
    <Layout
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}>
        <CoinPage
          isSignedIn={isSignedIn}
          user={user}
          providerId={providerId}
        />
    </Layout>
    );
  }}
  </FirebaseAuthConsumer>
  )
}



function App() {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
    <Router>
    <Switch>
    <Route exact path="/">
      <DashboardLayout />
    </Route>
    <Route path="/add-coin">
      <AddCoinLayout />
    </Route>
    <Route path="/login">
      <LoginLayout />
    </Route>
    <Route path="/promote">
      <PromoteLayout />
    </Route>
    <Route path="/coin/:id">
      <CoinPageLayout />
    </Route>    
  </Switch>
  </Router>
  </FirebaseAuthProvider>
  );
}

export default App;
