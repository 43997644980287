import React from 'react';
import firebase from "firebase/app";
import { Button } from "../components/Buttons/Buttons"
import { googleAuthProvider as provider, auth } from "../firebaseConfig"
import { BlackCard } from "../components/Cards/Card"
import LOGO from "./img/logo.png"

const signOut = () => {    
    return firebase.auth().signOut().then(() => {
        // Sign-out successful.        
      }).catch((error) => {
        // An error happened.
        alert("Error occurred when signing out")
        console.log(error)
      });
}

const signIn = () => {
    firebase.auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // ...
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}

const Login = ({
    isSignedIn,
    user,
    providerId
  }) => {
    return(
        <div class="flex justify-center items-center">
           <BlackCard>
           <img src={LOGO} height="450px" width="450px"/>
           {
                !isSignedIn
                ?
                <Button onClick={() => signIn() }>Login with Google</Button>
                :
                <Button onClick={() => signOut() }>Logout</Button>
            }
           </BlackCard>
            
        </div>
    )
}


export default Login;