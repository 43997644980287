import React, { useState, useEffect } from 'react';
import { Modal } from "react-responsive-modal";
import { FaGlobeAmericas }  from "react-icons/fa";
import { Comment, Form, Header } from 'semantic-ui-react';
import { Button } from '../Buttons/Buttons';
import styled  from "styled-components"
import { createClient } from '@supabase/supabase-js'
import { useStore } from '../../store'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)


const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: ${props => props.inputColor || "palevioletred"};
  background: papayawhip;
  border: none;
  flexDirection: row;
  width: 70%;
`;
//TOKEN MARKET CAP: https://stackoverflow.com/questions/66132843/is-there-a-way-to-get-the-market-cap-or-market-cap-rank-of-a-coin-using-the-bina
//https://coinmarketcap.com/api/
//modalData["comments"]
const telegram_icon = "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png"
const ExpandedCard = ({
    modalOpen,
    onCloseModal,
    modalData,    
    date,
    user
}) => {
    const state = useStore();
    const[comment, setComment] = useState('')

    useEffect(() => {
        //sort by time posted!
    },[])

    const handlePostComment = async (newComments, coinID) => {        
        const { data, error } = await supabase
        .from('coins')
        .update({ comments: newComments })
        .eq('id', coinID)
        if(error){
            alert("Error when handling upvote")
            console.log("UPVOTE ERROR", error)
        }
    }

    const postComment = async (user, comment, coinID) => {
        let ref = state.coinsView[date];        
        let orig = state.coinsView
        let commentObj = {
            name: user.displayName,
            posted: new Date().toISOString().substring(0, 10),
            comment: comment
        }
        modalData.comments.push(commentObj)
        //FInd correct obj to update in the ref
        ref.forEach(item => {
            if(item.id == modalData.id){
                ref.comments = modalData.comments
            }
        })
        //UPdate the current date by referencing ref
        orig[date] = ref
        state.setCoinsView(orig)
        handlePostComment(ref.comments, coinID)
        console.log(state.coinsView)
        setComment("")
    }

    return(        
        <div class="sm:w-1/2 superSmall:w-1/2">
        <Modal open={modalOpen} onClose={onCloseModal}  
        classNames={{
            overlay: ' background: rgba(36, 123, 160, 0.7);',
            modal: 'background: #b2dbbf;max-width: 500px;width: 100%;',
          }}         
        > 
          <div class="flex flex-col space-y-4">
                <div class="flex flex-row space-x-4">
                    <img height="50px" width="50px" src={modalData.logo} />
                    <p class="poppy text-3xl">{modalData.coin_name}</p>  
                    <p class="poppy text-xl">${modalData.coin_symbol}</p>                  
                </div>

                <div class="flex flex-row space-x-4 items-center justify-center cursor-pointer">
                    <p class="poppy text-gray-400 cursor-pointer">{modalData.contract_address}</p>
                    <img class="cursor-pointer" height="90px" width="90px" src={telegram_icon}/> 
                    <FaGlobeAmericas/>
                </div>                    
                
                <p>
                  {modalData.coin_description}
                </p>
                
                <p>What do you think of this token?</p>

                <div >
                    <Input value={comment} onChange={(e) => setComment(e.target.value)} type="text" />
                    <Button onClick={() => postComment(user, comment, modalData.id)}>Post</Button>
                </div>

                {
                  modalData.comments ? 
                  modalData.comments.map((comment, key) => {
                        return(                             
                            <div class="flex flex-row">
                                <div>
                                    <img class="cursor-pointer" height="90px" width="90px" src={"https://avatars.dicebear.com/api/male/asdfasdfasdfasf.svg"}/> 
                                </div>
                                <div class="flex flex-col border-b-2 border-gray-300">
                                    <div class="flex flex-row space-x-4">
                                        <p class="poppy text-2xl">{comment.name}</p>
                                        <p class="poppy text-sm">{comment.posted}</p>
                                    </div>
                                    <p class="poppy ">{comment.comment}</p>
                                </div>
                            </div>
                        )
                    })
                    :
                    <p>No commments</p>
                }
     
          </div>
        </Modal>  
        </div>      
    );
}

export default ExpandedCard;





/**
 * TEST DATA
 *   [{
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin be bussin, bussin",
                        "posted": "2 days ago",
                        "name": "Fred"
                    },
                    {
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin not bussin, bussin",
                        "posted": "5 days ago",
                        "name": "Yoria"
                    },
                    {
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin not bussin, bussin",
                        "posted": "5 days ago",
                        "name": "Yoria"
                    },
                    {
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin not bussin, bussin",
                        "posted": "5 days ago",
                        "name": "Yoria"
                    },
                    {
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin not bussin, bussin",
                        "posted": "5 days ago",
                        "name": "Yoria"
                    },
                    {
                        "avatar": "https://i.pinimg.com/originals/a7/79/fa/a779faa34c77b3cc87f9c90d24048cfa.png",
                        "comment": "This coin not bussin, bussin",
                        "posted": "5 days ago",
                        "name": "Yoria"
                    }]
 */