import React, { useState, useEffect } from 'react';
import firebase from "firebase/app";
import { Button } from "../components/Buttons/Buttons"
import { googleAuthProvider as provider, auth } from "../firebaseConfig"
import { BlackCard } from "../components/Cards/Card"
import { useLocation } from 'react-router-dom';
//import { Button } from '../Buttons/Buttons';
import styled  from "styled-components"
import { FaGlobeAmericas,FaAngleDown }  from "react-icons/fa";
import { useStore } from '../store'
import { createClient } from '@supabase/supabase-js'
import { useHistory } from 'react-router-dom';
import { findDay } from "../utils/utils"
import BscScanLogo from "./img/bscscan-logo.png"
import BscLogo from "./img/bsc-logo.png"
import BoggedLogo from "./img/bogged-logo.png"
import TelegramLogo from "./img/telegram-logo.png"
import moment from "moment"
import { GreenCircle, RedCircle } from "../components/Circles/Circle"



const ICON = "https://cloud.icons8.com/apps/files_sharing/publicpreview/4WjsHDGEbcwJseL?fileId=237650&file=/4_Victory%20hand%2001.png&x=1440&y=900&a=true"
const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: ${props => props.inputColor || "black"};
  background: papayawhip;
  border: none;
  flexDirection: row;
  width: 70%;
`;

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

const CoinPage = ({
    isSignedIn,
    user,
    providerId,
  }) => {
    const location = useLocation();        
    const state = useStore();
    const[comment, setComment] = useState('')
    const[loading, setLoading] = useState(true)
    const history = useHistory();
    const[comments,setComments] = useState([])

    useEffect(() =>{        
        const path = location.pathname.split("/")
        const commentId = path[path.length - 1]
        fetchComments(commentId)
        setLoading(false)
    }, [])

    const fetchComments = async (commentId) => {
        const { data, error } = await supabase
        .from('coins')
        .select("*")
        .eq('id', commentId)
        if(data){            
            setComments(data[0])
        }        
        if(error){
            alert("Error when fetching comments")
            console.log("UPVOTE ERROR", error)
        }
        
    }

    const handlePostComment = async (newComments, coinID) => {        
        const { data, error } = await supabase
        .from('coins')
        .update({ comments: newComments })
        .eq('id', coinID)
        if(error){
            alert("Error when handling upvote")
            console.log("UPVOTE ERROR", error)
        }
    }

    const postComment = async (user, comment, coinID) => {
        if(!comment){
            alert("Please fill in the form")
            return
        }
        if(!isSignedIn){
            history.push('/login')
            return
        }
        //FIX: gets empty cause this is loaded on the dashboard only
        
        let ref = comments;        
        let orig = state.coinsView
        let commentObj = {
            name: user.displayName,
            posted: new Date().toISOString(),
            comment: comment
        }
        comments.comments.push(commentObj)        
        handlePostComment(comments.comments, coinID)        
        setComment("")
    }

    if(loading){
        return "Loading"
    }
    return(
        <div styles={{ height: '500px', overflowY: 'scroll' }} class="flex justify-center items-center ">
           <BlackCard>               
           <div  class="flex flex-col justify-center items-center">

                    <div class="flex 
                    sm:flex-col sm:space-y-2
                    superSmall:flex-col superSmall:space-y-2
                    lg:flex-row
                    lg:space-x-4 
                    my-2">
                        <img height="50px" width="50px" src={comments.logo} />
                        <p class="poppy text-3xl text-black">{comments.coin_name}</p>  
                        <p class="poppy text-xl text-black">${comments.coin_symbol}</p>                                                 
                    </div>
                    
                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-2xl my-2"> 
                        <a href={`https://bscscan.com/token/${comments.contract_address}`} target="_blank"><img src={BscScanLogo} height="100px" width="100px"/></a>
                    </p>
                    </div>

                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-2xl my-2"> 
                        <a href={`${comments.telegram_link}`}><img src={TelegramLogo} target="_blank" height="50px" width="50px"/></a>
                    </p>
                    </div>

                    <div class="flex flex-row">            
                    
                    <p class="text-black poppy text-2xl my-2"> 
                    <a href={`${comments.website}`} target="_blank"><FaGlobeAmericas/></a>
                    </p>
                    </div>
                    
                    

                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-2xl my-2"> 
                        <a href={`https://charts.bogged.finance/?token=${comments.contract_address}`} target="_blank"><img src={BoggedLogo} height="50px" width="50px"/></a>
                    </p>
                    </div>
                    

                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-md my-2"> 
                        Market Cap: {comments.marketcap ? comments.marketcap : "Unknown"}
                    </p>
                    </div>


                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-md my-2"> 
                        Team doxxed: {comments.doxxed ? "Yes" : "No"}
                    </p>
                    </div>

                    <div class="flex flex-row">                    
                    <p class="text-black poppy text-md my-2"> 
                        Contract audited: {comments.audited ? "Yes" : "No"}
                    </p>
                    </div>

                    <p class="text-black poppy text-2xl my-2"> 
                      {comments.coin_description}
                    </p>

                    <p class="poppy text-black text-xl">What do you think of this token?</p>

                    <div >
                        <Input value={comment} onChange={(e) => setComment(e.target.value)} type="text" />
                        <Button onClick={() => postComment(user, comment, comments.id)}>Post</Button>
                    </div>
                    {
                      comments && comments.comments ? 
                      comments.comments.sort((a, b) => {
                         return a.posted > b.posted
                      }).map((comment, key) => {
                            return(                        
                                <div>                            
                                    <div class="flex flex-col space-y-2 border-b-2 border-gray-300">                                                                                                
                                            <div class="flex flex-row md:space-x-4 lg:space-x-4 
                                            small:space-x-4 superSmall:space-x-2">                                                
                                           
                                                <img class="cursor-pointer" height="40px" width="40px" src={`https://avatars.dicebear.com/api/initials/${comment.name}.svg`}/>
                                                           
                                                <p class="poppy text-md text-black">{comment.name}</p>
                                                <p class="poppy text-sm text-black">{
                                                
                                                moment(comment.posted).fromNow()
                                                
                                                }</p>
                                            </div>
                                            <p class="poppy text-black text-xl">{comment.comment}</p>                                
                                    </div>

                                </div>
                            )
                        })
                        :
                        <div class="flex flex-col w-screen">                
                            <p class="text-xl poppy text-black">No coin found</p>
                        </div>
                    }
                </div>
           </BlackCard>
            
        </div>
    )
}


export default CoinPage;





