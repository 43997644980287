import create from 'zustand'
import { coins } from "./mockData/coins"

//state #1
export const useStore = create(set => ({
  modalOpen: false,
  modalData: [],
  sortedCoins: [],
  coins: [], // all the coins
  coinsView: [], //coins that we are slicing one by one
  viewLimits: {},
  showMore: true,
  indexArr: 4,
  leaderboard: [],
  promotedCoins: [],
  trackPromotions: [],
  setTrackPromotions: (promotions) => set(state => ({trackPromotions: promotions})),
  setPromotedCoins: (promotedCoins) => set(state => ({promotedCoins: promotedCoins})),
  setLeaderboard: (leaderboard) => set(state => ({leaderboard: leaderboard})),
  setViewLimits: (limits) => set(state => ({viewLimits: limits})),
  setIndexArr: (num) => set(state => ({indexArr: num})),
  setShowMore: (bool) => set(state => ({showMore: bool})),
  setCoins: (coins) => set(state => ({coins: coins})),
  setSortedCoins: (coins) => set(state => ({sortedCoins: coins})),
  setCoinsView: (coinsView) => set(state => ({coinsView: coinsView})),
  setModalData: (data) => set(state => ({ modalData: data }) ),
  openModal: () => set(state => ({ modalOpen: true }) ),
  onCloseModal: () => set(state => ({ modalOpen: false }) ),
  unsetModalData: () => set(state => ({ modalData: [] }) ),
  increasePopulation: () => set(state => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 })
}))

