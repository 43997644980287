import React, { useState, useEffect } from 'react'
import { Select } from "@supabase/ui";
import { Button } from "../components/Buttons/Buttons"
import { BlackCard } from "../components/Cards/Card"
import { createClient } from '@supabase/supabase-js'
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import sgMail from "@sendgrid/mail";

sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY)

//HAVE THESE IN THE ENV FOR PROD
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL

const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
//const supabaseKey = 
const supabase = createClient(supabaseUrl, supabaseKey)

const AddCoin = ({
  isSignedIn,
  user,
  providerId
}) => {    
    const[name, setName] = useState('')
    const[symbol, setSymbol] = useState('')
    const[description, setDescription] = useState('')
    const[telegram, setTelegram] = useState('')
    const[website, setWebsite] = useState('')
    const[contractAddress, setContractAddress] = useState('')
    const[chain, setChain] = useState('BSC')
    const[additionalLinks, setAdditionalLinks] = useState('')
    const[logo, setLogo] = useState('')
    const[launchDate, setLaunchDate] = useState(new Date().toISOString())    
    const history = useHistory();

    const validateFields = () => {
      if(name === ""){
        alert("Coin name cannot be empty")
        return false
      }else if(symbol === ""){
        alert("Coin symbol cannot be empty")
        return false
      }else if(description === ""){
        alert("Coin description cannot be empty")
        return false
      }
      else if(contractAddress === ""){
        alert("Coin contract address cannot be empty")
        return false
      }
      else if(logo === ""){
        alert("Coin logo cannot be empty")
        return false
      }
      else if(chain === ""){
        alert("Chain cannot be empty")
        return false
      }
      else if(!launchDate){
        alert("Launch date cannot be empty")
        return false
      }
      return true
    }

    const postCoin = async () => {
        
        if(!isSignedIn){
          history.push('/login')
          return
        }

        if(!validateFields()){
          return
        }

        if(user.email === "yasimba90@gmail.com" || user.email === "Deffduke@gmail.com"){
          //send to main db
          const { data, error } = await supabase
          .from('coins')
          .insert([          
            { coin_name: name,
              coin_symbol: symbol,
              coin_description: description ,
              telegram_link: telegram ,
              website: website ,
              contract_address: contractAddress ,
              chain: chain ,
              additional_links: additionalLinks ,
              logo: logo ,
              launch_date: new Date().toISOString(),
              audit: false,
              doxxed: false,
              date_added: new Date().toISOString(),
              upvoted_users: [],       
              posted_by: user.email,
              comments: [],  
            },            
          ]);
          if(data){            
            setName("")
            setSymbol("")
            setDescription("")
            setTelegram("")
            setWebsite("")
            setContractAddress("")            
            setAdditionalLinks("")
            setAdditionalLinks("")
            setLaunchDate(new Date().toISOString())
            alert("Done")
            window.location.reload()
          }
          if(error){
              console.log("ERROR IS", error)
              alert("Error occurred while posting")
          }
        }else{
          //send to coin_requests
          const { data, error } = await supabase
          .from('coin_requests')
          .insert([          
            { coin_name: name,
              coin_symbol: symbol,
              coin_description: description ,
              telegram_link: telegram ,
              website: website ,
              contract_address: contractAddress ,
              chain: chain ,
              additional_links: additionalLinks ,
              logo: logo ,
              launch_date: new Date().toISOString(),
              audit: false,
              doxxed: false,
              date_added: new Date().toISOString(),
              upvoted_users: [],       
              posted_by: user.email,
              comments: [],  
            },            
          ]);
          if(data){            
            setName("")
            setSymbol("")
            setDescription("")
            setTelegram("")
            setWebsite("")
            setContractAddress("")            
            setAdditionalLinks("")
            setAdditionalLinks("")
            setLaunchDate(new Date().toISOString())
            alert("Request posted. Details will be reviewed by our team and we will contact you shortly")
            window.location.reload()
          }
          if(error){
              console.log("ERROR IS", error)
              alert("Error occurred while posting")
          } 
        }
             
    }


    return(
      <div class="flex flex-col space-y-4 w-screen">
          <BlackCard>   
            <div class="space-y-2">
              <p class="text-black poppy ">Token Name</p>
              <input     
                class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
                placeholder="Token Name"
                onChange={e => setName(e.target.value)}                                                                       
              />   

              <p class="text-black poppy ">Token Symbol</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              placeholder="Token Symbol"
              onChange={e => setSymbol(e.target.value)}                                   
              />

              <p class="text-black poppy ">Description</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              onChange={e => setDescription(e.target.value)}                            
                placeholder="Token description"
              />

              <p class="text-black poppy ">Telegram</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              onChange={e => setTelegram(e.target.value)}                            
                placeholder="t.me/myTokenName"
              />
              
              <p class="text-black poppy ">Website</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              onChange={e => setWebsite(e.target.value)}                            
                placeholder="www.mytoken.com"
              />


              <p class="text-black poppy ">Contract address</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              onChange={e => setContractAddress(e.target.value)}                            
                placeholder="0xAbc6aasf807MytokenAddress"
              /> 


              <p class="text-black poppy ">Chain</p>
              <Select value={chain} onChange={e => setChain(e.target.value)}   >
                <Select.Option>BSC</Select.Option>        
              </Select>


              <p class="text-black poppy ">Additional links</p>
              <input
              class="border
              rounded-lg border-black h-12 w-full 
              px-4"
              onChange={e => setAdditionalLinks(e.target.value)}                            
                placeholder="link1, link2, link3"
              />

              
              <p class="text-black poppy ">Logo link</p>
              <input
              class="border
                       rounded-lg border-black h-12 w-full 
                       px-4"
              onChange={e => setLogo(e.target.value)}                            
                placeholder="Link to logo"
              />   


              <p class="text-black poppy ">Token Launch date</p>
               <TextField
                id="date"
                
                type="date"
                defaultValue={new Date().toISOString()}
                onChange={e => setLaunchDate(e.target.value)}                              
                InputLabelProps={{
                  shrink: true,
                }}
              />

            
              <div class="flex flex-col justify-center items-center my-8">
                  <Button size="xlarge" onClick={()=>postCoin()}>Post</Button>
                  <p class="text-black poppy">{!isSignedIn ? "You need to sign in to post" : null}</p>
              </div>
              </div>
          </BlackCard>
      </div>

    );

 
}

export default AddCoin