//https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np
//https://linguinecode.com/post/prevent-re-renders-react-functional-components-react-memo
import React, { useState, useEffect, useRef  } from 'react'
import { Input } from "@supabase/ui";
//import { Card } from "@supabase/ui";
import { createClient } from '@supabase/supabase-js'
import styled  from "styled-components"
import {FaArrowCircleUp, FaSortNumericDown} from 'react-icons/fa'
import {FaRegHandPointRight} from 'react-icons/fa'
import {
    slice, concat, 
} from 'lodash';
import _ from 'lodash'
import LazyLoad from 'react-lazyload';
import { motion } from "framer-motion";
import Reward from 'react-rewards';
import publicIp from "public-ip";
import ReCAPTCHA from "react-google-recaptcha";
import DataTable from '../components/Table/Table'
import Leaderboard from "../components/Table/LeaderTable"
import "react-responsive-modal/styles.css";
import ExpandedCard from "../components/Modals/ExpandedCard"
import { coins } from "../mockData/coins"
import { useStore } from "../store"
import SkeletonCard from '../components/SkeletonCard/SkeletonCard'
import BscScanLogo from "./img/bscscan-logo.png"
import { useHistory } from 'react-router-dom';
import PromotionsTable from '../components/Table/PromotionsTable';




const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)


const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.upvoted ? "palevioletred" : "white"};
  color: ${props => props.upvoted ? "white" : "#262932"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

const Circle = styled.div`
 height: 100px;
 width: 100px;
 border-style: solid;
 border-width: 5px;
 border-radius: 50%;
 border-color: black;
`

const GreenCircle = styled(Circle)`
background-color: green;
border-color: green;
height: 30px;
width: 30px;
`;


const Line = styled.hr`
  color: rgba(0, 0, 0, 0.65);
  margin-left: -100px;
  width: 400px;  
`;

const OrangeCard = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "orange" : "white"};
  color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid orange;
  border-radius: 3px;
`;

const Card = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 1px solid black;
  border-radius: 5px;
`;


const BlackCard = styled(Card)`
 background-color: white;
 font-color: black;
`;

const DarkCard = styled(Card)`
background-color: black;
font-color: white;
`;

/*

<ReCAPTCHA
    sitekey="Your client site key"
    onChange={onChange}
  />
  */
const Dashboard = ({
    user
}) => {

    let buttonRef = useRef(null)    
    //const [index,setIndex] = useState(LIMIT);
    const[upvoted, setUpvoted] = useState(false)
    const[user_id, setUserId] = useState('')
    const[modalOpen, setModalOpen] = useState(false)
    const[modalData, setModalData] = useState([])
    const[promotedCoins, setPromotedCoins] = useState([])
    const[loading, setLoading] = useState(true)    
    const state = useStore()
    const LIMIT = 4;
    const history = useHistory();  


    //initial load
    useEffect(() => {


        async function getIpAddress(){
            const ipAddress =  await publicIp.v4({
                fallbackUrls: [ "https://ifconfig.co/ip" ]
            });
            setUserId(ipAddress)                    
        }
        getIpAddress()

        //TODO: Sort by date first
        let cCopy;
        async function fetchData(){

            //Fetch coins
            let { data: coins, error } = await supabase
            .from('coins')
            .select('*')

            if(error){
                console.log("ERROR IS", error)
            }

            //Fetch promoted coins
            let { data: promoted_coins, e } = await supabase
            .from('promoted_coins')
            .select(`
              coins_id,
              coins (
                *
              )
            `)
            if(e){
                console.log("ERROR WHEN FETCHING PROMOTIONS", e)
            }
            if(promoted_coins){
                let promotionsArr = []
                let trackPromotions = []
                let shuffled = shuffle(promoted_coins)
                for(let i = 0; i<shuffled.length; i++){
                    promotionsArr.push(shuffled[i].coins)
                    trackPromotions.push(shuffled[i].coins.id)
                }
               // console.log("SETTING PROMOTED COINS AS", promotionsArr)
                state.setPromotedCoins(promotionsArr)
                state.setTrackPromotions(trackPromotions)
            }

            
            //TODO: SORT THE LIST!!!!
            coins.sort((a, b) => a.upvoted_users.length > b.upvoted_users.length ? -1 :
            (a.upvoted_users.length  < b.upvoted_users.length  ? 1 : 0));


            cCopy = coins
            //RESET SHOW MORE BUTTON
            let sorted = {}
            let viewLimits = {}
            let sortedView = {}
            let sortedCpy = {} //store previous state of coins
            coins.forEach(coin => {
                if(!sorted[coin.date_added]){
                  sorted[coin.date_added] = []
                  sortedCpy[coin.date_added] = []
                  viewLimits[coin.date_added] = {}        //initialize viewing limiits
                  viewLimits[coin.date_added]["VIEWS_LIMIT"] = 4          //initialize viewing limiits
                  //Current index of the specific date in iteration
                  viewLimits[coin.date_added]["COIN_INDEX"] = 4
                  viewLimits[coin.date_added]["SHOW_MORE"] = true
                  sorted[coin.date_added].push(coin)
                  sortedCpy[coin.date_added].push(coin)
                }else{
                  sorted[coin.date_added].push(coin)
                  sortedCpy[coin.date_added].push(coin)
                }
            })                     

            sortedView = sorted

            Object.keys(sorted).forEach((date, key) => {
                let slicedData = sorted[date].slice(0,LIMIT)
                sortedView[date] = slicedData
              })
            
            //SORT BY DATE, THEN UPDATE
            state.setCoinsView(sortedView)
            state.setSortedCoins(sortedCpy)
            state.setLeaderboard(cCopy)            
            state.setViewLimits(viewLimits)            
        }
        fetchData()
        setLoading(false)
    }, []);

    const config = {
        emoji: ["🚀", "🔥", "🎈", "❤️", "🎈", "🎈", "🔥", "🚀"],
        elementCount: 100,
        spread: 150,
        zIndex: 9999,
        lifetime: 100
      };

      const shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }
      
      
    //This is called whenever you make a call to loadMore()
    const loadMore = (loadDate) => {
        //.sort((a, b) => b.upvotes > a.upvotes ? 1 : -1);
      //state.setCoinsView(sortedData);
        
        Object.keys(state.sortedCoins).forEach((date, key) => {
        if(date === loadDate){
            const coins = state.sortedCoins[date] //coins at this date
            let coinsView = state.coinsView //view at this date
            
            let viewLimits = state.viewLimits
            let viewLimitsCpy = state.viewLimits[date]
            //console.log("OLDLEN", Object.keys(sortedView[date]).length)
            let currIndex = viewLimitsCpy["COIN_INDEX"]
            //console.log("CURRENT INDEX IS", currIndex, "SORTED IS", state.sortedCoins[date])
            const newIndex = viewLimitsCpy["COIN_INDEX"] + LIMIT;//change limit to view Limit
            viewLimitsCpy["COIN_INDEX"] = newIndex;
            const newShowMore = newIndex < (coins.length - 1); 
            const oldList = coins
            //add to the list based on the current index of the main coins array
            const newList = concat(coinsView[date], slice(state.sortedCoins[date], currIndex, newIndex));    
            coinsView[date] = newList
            
            viewLimits[date] = viewLimitsCpy
            viewLimits[date]["SHOW_MORE"] = newShowMore
            state.setViewLimits(viewLimits)
            state.setCoinsView(coinsView)
            //Update state to show more cards
            //state.setShowMore(newShowMore);1
           }
        })

    }


    const handleUpvote = async (newUpvotes, coinID) => {
        const { data, error } = await supabase
        .from('coins')
        .update({ upvoted_users: newUpvotes })
        .eq('id', coinID)
        if(error){
            alert("Error when handling upvote")
            console.log("UPVOTE ERROR", error)
        }
    }



    const onUpvotePromotions = async (item_id, user_id, currDate) => {   
        
        
        //Create copy of the current object to update
        let sortedCpy = state.promotedCoins
        //make upvotes the length of upvoted users
        //Update the object              
        //Update promotions count
        let rowToUpdate = 0;    
        let newUpdate = []        
        // sconsole.log("PROMOTIONS BEFORE", state.promotedCoins[0].upvoted_users.length)
        for(let i = 0; i < sortedCpy.length; i++){
            let item = sortedCpy[i];
            if(item_id === item.id){  
                rowToUpdate = i;                      
                const idx = item.upvoted_users.indexOf(user_id)
                if(idx > -1){
                    item.upvoted_users.splice(idx, 1)                    
                    //Push to DB
                    await handleUpvote(item.upvoted_users, item.id)
                }
                else{                    
                    item.upvoted_users.push(user_id)
                    //Push to DB
                    await handleUpvote(item.upvoted_users, item.id)
                }
                newUpdate = item;
            }  
        }
        //console.log("PROMOTIONS AFTER", state.promotedCoins[rowToUpdate].upvoted_users.length)
        state.setPromotedCoins(sortedCpy)
        
    }

    //USE MEMO!!!!!!!
    //should not be called on LOAD!!!
    //k is useless    
    const onUpvote = async (item_id, list_id, user_id, currDate) => {                
        //Create copy of the current object to update
        let sortedCpy = state.sortedCoins
        //make upvotes the length of upvoted users
        //Update the object              
        
        Object.keys(sortedCpy).forEach(date => {               
            if(date === currDate){                
                sortedCpy[date].forEach(async item => {                    
                    if(item.id === item_id){                        
                        const idx = item.upvoted_users.indexOf(user_id)
                        if(idx > -1){
                            item.upvoted_users.splice(idx, 1)
                            //Push to DB
                            await handleUpvote(item.upvoted_users, item.id)
                        }
                        else{
                            item.upvoted_users.push(user_id)
                            //Push to DB
                            await handleUpvote(item.upvoted_users, item.id)
                        }
                    }                            
                })   
            }        
        })
            
        //Update new state with the copied object
        state.setSortedCoins(sortedCpy)                

        //regenerate coinsView using the indices of viewLimits object
        let sorted = state.sortedCoins
        let sortedView = state.coinsView
        
        Object.keys(sorted).forEach((date, key) => {
            let viewLimits = state.viewLimits[date]
            let slicedData = sorted[date].slice(0, viewLimits["COIN_INDEX"]) //maintain current expansion
            sortedView[date] = slicedData            
        })
        
        state.setCoinsView(sortedView)        
        //setUpvoted(!upvoted)       
    }

    const onOpenModal = () => {
        setModalOpen(true)
        alert(state.modalData)
      };
    
    const  onCloseModal = () => {
        setModalOpen(false)
      };
    //justify-center pushes vertically center


    if(loading){
        return <SkeletonCard/>
    }
    return(
       <div class="flex flex-col items-center min-w-screen">            

            <p class="poppy text-black font-bold my-4 text-3xl flex-row">Buzzzin' Coins</p>  
     
            <p class="move">🐝</p> 

            <div class="
                        min-w-screen
                        lg:flex lg:justify-center lg:items-center lg:flex lg:flex-row
                        md:justify-center md:items-center md:flex md:flex-row
                        sm:justify-center sm:items-center sm:flex sm:flex-col
                        superSmall:justify-center superSmall:items-center superSmall:flex superSmall:flex-col ">  
                {
                   state.promotedCoins && state.promotedCoins.length > 0 && <div>                   
                   <PromotionsTable                                        
                       data={state.promotedCoins.slice(0, 5)}                                        
                       user_id={user_id}
                        onUpvote={onUpvotePromotions}
                        buttonRef={buttonRef}
                        config={config}
                        loadMore={loadMore}
                        showMore={state.showMore}                     
                   />       
                   </div> 
                        /**
                         * <img height="100px" width="100px" src={BscScanLogo}/> 
                         */
                    }
                                                        
            </div>

            
                <BlackCard>          
                <p class="poppy text-black font-bold mb-4">Upcoming launches</p>
                       {
                           /*[1,2,3,4,5].map((v, k) => {
                               return(
                                <div class="flex flex-row space-x-4">
                                    <p class="poppy text-black">Coins launched today</p>
                                    <p class="poppy text-green-600">10</p>                                    
                                    <div class="flex flex-col ">
                                    <p class="poppy text-black">This coin was launched for the comm</p>
                                    </div>
                                </div>
                               )
                           })*/

                           
                            <div class="flex flex-row space-x-4">
                                <p class="poppy text-black">No upcoming launches</p>                                                                
                            </div>
                           
                       }
                </BlackCard> 
                <div class="lg:flex lg:flex-col lg:justify-center lg:items-center  lg:h-1/2 lg:mb-4 lg:space-y-4
                            md:col-span-1
                            sm:flex  sm:mx-8 sm:flex-col sm:space-y-4  sm:justify-center      
                            superSmall:mx-48  superSmall:space-y-4    superSmall:justify-center 
                              ">      
                              <p class="poppy text-black text-yellow-500 font-bold mb-4 text-xl
                              sm:mx-8 sm:my-4
                              superSmall:mx-8 superSmall:my-4">Leaderboard</p>
                    {
                      state.leaderboard.length > 0 &&                                                                       
                                    <div>                   
                                    <Leaderboard                                        
                                        data={state.leaderboard.slice(0, 5)}                                        
                                        user_id={user_id}
                                        onUpvote={onUpvote}
                                        buttonRef={buttonRef}
                                        config={config}
                                        loadMore={loadMore}
                                        showMore={state.showMore}                                 
                                    />       
                                    </div>                                                                             
                    }      
                                
                </div>                                                    
                <div class="lg:flex lg:flex-col lg:justify-center lg:items-center  lg:h-1/2 lg:mb-4 lg:space-y-4
                            md:col-span-1
                            sm:flex  sm:mx-8 sm:flex-col sm:space-y-4  sm:justify-center      
                            superSmall:mx-48  superSmall:space-y-4    superSmall:justify-center 
                              ">      
                              <p class="poppy text-black font-bold mb-4 text-xl
                              sm:mx-12 sm:my-4
                              superSmall:mx-12 superSmall:my-4 text-yellow-500">Recent launches</p>
                    {
                       Object.keys(state.viewLimits).length > 0 && state.coinsView && Object.keys(state.coinsView).reverse().map((date, key) => {
                            let data = state.coinsView[date]                                
                            return(
                                    <div>                   
                                    <DataTable                                        
                                        data={data}
                                        user_id={user_id}
                                        onUpvote={onUpvote}
                                        buttonRef={buttonRef}
                                        config={config}
                                        loadMore={loadMore}
                                        showMore={state.showMore}
                                        dateAdded={date}
                                        date={date}
                                    />       
                                    </div>
                                )                                   
                        })        
                    }      
                                
                </div>
       </div> 
    )
} 

export default Dashboard;